import React, { useCallback } from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';

import {
  CheckboxAlphabeticalGroupItemsType,
  CheckboxAlphabeticalGroupIdType,
  CheckboxAlphabeticalGroupI18nSearchPlaceholder,
  CheckboxAlphabeticalGroupSearchValue,
  CheckboxAlphabeticalGroupSelectedItemsFetched,
  CheckboxAlphabeticalGroupFetchMoreButton,
  CheckboxAlphabeticalGroupOnSearch,
  CheckboxAlphabeticalGroupOnChange,
  CheckboxAlphabeticalGroupOnItemChange
} from './CheckboxAlphabeticalGroup.types';

import { Translate } from '../Translate';
import { LoadingSkeleton } from '../LoadingSkeleton';

import { CheckboxAlphabeticalGroupItem } from './helpers/CheckboxAlphabeticalGroupItem';
import { CheckboxAlphabeticalGroupSearch } from './helpers/CheckboxAlphabeticalGroupSearch';
import { CheckboxAlphabeticalGroupSelected } from './helpers/CheckboxAlphabeticalGroupSelected';

import { words } from '../../locales/keys';
import { GADataType } from '../../types';

export interface CheckboxAlphabeticalGroupProps {
  name: string;
  value?: CheckboxAlphabeticalGroupIdType[];
  sortedItems?: CheckboxAlphabeticalGroupItemsType;
  selectedItems?: CheckboxAlphabeticalGroupItemsType;
  itemsFetched?: CheckboxAlphabeticalGroupSelectedItemsFetched;
  i18nSearchPlaceholder?: CheckboxAlphabeticalGroupI18nSearchPlaceholder;
  searchValue?: CheckboxAlphabeticalGroupSearchValue;
  disabled?: boolean;
  fetchMoreButton?: CheckboxAlphabeticalGroupFetchMoreButton;
  onSearch?: CheckboxAlphabeticalGroupOnSearch;
  onChange: CheckboxAlphabeticalGroupOnChange;
}

function CheckboxAlphabeticalGroup({
  dataGa,
  disabled,
  fetchMoreButton,
  i18nSearchPlaceholder,
  itemsFetched,
  name,
  onChange,
  onSearch,
  searchValue,
  selectedItems = [],
  sortedItems = [],
  value = []
}: CheckboxAlphabeticalGroupProps & GADataType) {
  const handleItemChange = useCallback<CheckboxAlphabeticalGroupOnItemChange>(
    ({ id }) =>
      onChange(includes(value, id) ? without(value, id) : [...value, id]),
    [onChange, value]
  );
  const handleRemove = useCallback(
    (id) => onChange(without(value, id)),
    [onChange, value]
  );

  return (
    <div className="mt-3 flex flex-col space-y-2">
      {onSearch ? (
        <CheckboxAlphabeticalGroupSearch
          dataGa={dataGa}
          i18nSearchPlaceholder={i18nSearchPlaceholder}
          searchValue={searchValue}
          onSearch={onSearch}
        />
      ) : null}

      <div className="border dark:border-gray-700 overflow-hidden rounded-lg -mx-2">
        <LoadingSkeleton
          loaded={itemsFetched}
          className="overflow-y-auto max-h-80 p-2"
          count={25}
        >
          <div className="overflow-y-auto max-h-80 p-2">
            {isEmpty(sortedItems) ? (
              <div className="p-2 text-center">
                <Translate id={words.noSearchResults} />
              </div>
            ) : null}
            <ul>
              {sortedItems.map(({ id, label }, index, array) => (
                <CheckboxAlphabeticalGroupItem
                  dataGa={dataGa}
                  key={id}
                  id={id}
                  name={name}
                  label={label}
                  prevItemLabel={array[index - 1]?.label}
                  first={index === 0}
                  checked={includes(value, id)}
                  disabled={disabled}
                  onChange={handleItemChange}
                />
              ))}
            </ul>
            {fetchMoreButton}
          </div>
        </LoadingSkeleton>
      </div>

      <CheckboxAlphabeticalGroupSelected
        dataGa={dataGa}
        selectedItems={selectedItems}
        onRemove={handleRemove}
      />
    </div>
  );
}

export default CheckboxAlphabeticalGroup;
