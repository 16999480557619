import { useCallback } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';
import size from 'lodash/size';

import { FilterBadgeProps } from '../types';
import { GADataType, ID } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { CheckboxItemsType } from '../../CheckboxGroup';
import { Translate } from '../../Translate';
import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';

interface ItemsFilterBadgeProps extends FilterBadgeProps {
  items: CheckboxItemsType;
}

function ItemsFilterBadge({
  dataGa,
  filters,
  i18nLabel,
  items = [],
  name,
  onRemoveFilter,
  filterField
}: ItemsFilterBadgeProps & GADataType) {
  const handleRemoveFilter = useCallback(() => {
    onRemoveFilter(omit(filters, name));
  }, [onRemoveFilter, filters, name]);

  const filterValuePath = filterField ? `${name}.${filterField}` : name;

  const filterValue: ID[] | null = get(filters, filterValuePath, []);

  const idsCount = size(filterValue);

  return idsCount > 0 ? (
    <span className="bg-blue-500 bg-opacity-20 dark:text-gray-200 inline-flex items-center m-0.5 px-3 py-1.5 rounded-md text-gray-800 text-sm">
      <Translate id={i18nLabel || name} />: &nbsp;
      {filterValue && idsCount === 1
        ? get(
            find(items, ({ id }) => id === filterValue[0]),
            'label'
          )
        : idsCount}
      <PureIconButtonHelper
        className="flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white"
        dataGa={dataGa}
        icon={IconsEnum.X}
        iconClassName="h-3.5 w-3.5"
        onClick={handleRemoveFilter}
      />
    </span>
  ) : null;
}

export default ItemsFilterBadge;
