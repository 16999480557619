import React, { ReactNode } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { Translate } from '../../../../../helpers/Translate';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../locales/keys';

interface SelectedLibraryItemsSidebarProps {
  children: ReactNode;
  footer?: ReactNode;
  i18nTitle: string;
  onClose: () => void;
  selectedItemsCount?: number;
  withoutBackGround?: boolean;
}

function SelectedLibraryItemsSidebar({
  children,
  footer,
  i18nTitle,
  onClose,
  selectedItemsCount,
  withoutBackGround
}: SelectedLibraryItemsSidebarProps) {
  return (
    <aside
      id="side-panel"
      className="fixed pb-10 sm:pb-0 top-0 right-0 xl:relative h-full shrink w-full xl:w-auto z-10"
    >
      <div
        className={
          withoutBackGround
            ? 'z-10 shadow-lg xl:shadow-none relative overflow-y-auto w-80 3xl:w-96 4xl:w-112 border-l border-gray-200 dark:border-gray-700 h-full ml-auto'
            : 'z-10 shadow-lg xl:shadow-none relative overflow-y-auto w-80 3xl:w-96 4xl:w-112 border-l border-gray-200 dark:border-gray-700 h-full bg-white dark:bg-gray-900 ml-auto'
        }
      >
        <div className="flex flex-col h-full w-full">
          <PureTooltipIconButtonHelper
            className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
            icon={IconsEnum.X_OUTLINE}
            tooltipI18nText={words.hide}
            tooltipPlacement={TooltipPlacement.LEFT}
            onClick={onClose}
          />
          <div className="flex-1 overflow-hidden relative">
            <div className="absolute inset-0 overflow-y-auto overflow-x-hidden py-4 text-sm">
              <div className="p-4">
                <h3 className="font-semibold break-words text-lg leading-snug flex items-center gap-2">
                  <Translate id={i18nTitle} />
                  <span className="py-0.5 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600">
                    {selectedItemsCount}
                  </span>
                </h3>
              </div>
              <div className="p-4">{children}</div>
            </div>
          </div>
          {footer ? (
            <div className="border-gray-200 border-t dark:border-gray-700 flex-shrink px-2 py-3">
              {footer}
            </div>
          ) : null}
        </div>
      </div>
      <div
        id="side-panel-backdrop"
        className="absolute inset-0 z-0 bg-gray-200 bg-opacity-30 dark:bg-gray-800 dark:bg-opacity-50 xl:hidden"
        onClick={onClose}
      />
    </aside>
  );
}

export default SelectedLibraryItemsSidebar;
