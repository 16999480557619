import React from 'react';

import {
  FetchItemsFetchingNextPage,
  FetchItemsLimit,
  FetchItemsLoadMoreItems,
  FetchItemsPage,
  FetchItemsTotalCount,
  GADataType
} from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { useCurrentUser } from '../../../auth/hooks/useAuth';
import { useTranslate } from '../../../common/hooks/useTranslate';

import { Loading } from '../../Loading';
import { PureIconButtonHelper } from '../PureIconButtonHelper';

import { words } from '../../../locales/keys';

interface LoadMoreButtonHelperProps {
  action?: string;
  itemsFetchingNextPage: FetchItemsFetchingNextPage;
  itemsTotalCount: FetchItemsTotalCount;
  limit: FetchItemsLimit;
  loadMoreItems: FetchItemsLoadMoreItems;
  page: FetchItemsPage;
}

function LoadMoreButtonHelper({
  action,
  dataGa,
  itemsFetchingNextPage,
  itemsTotalCount,
  limit,
  loadMoreItems,
  page
}: LoadMoreButtonHelperProps & GADataType) {
  const currentUser = useCurrentUser();

  const { t } = useTranslate();

  const itemsLeftToLoad = itemsTotalCount - page * (limit || 0);

  const loadMoreText = currentUser.hasPermissions(action)
    ? t(words.loadMore) + ` (${itemsLeftToLoad})`
    : t(words.loadMore);

  return (
    <div className="flex justify-center items-center">
      <Loading loaded={!itemsFetchingNextPage}>
        <PureIconButtonHelper
          dataGa={dataGa}
          disabled={itemsFetchingNextPage}
          className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 bg-transparent dark:bg-transparent"
          icon={IconsEnum.ARROW_SM_DOWN}
          onClick={loadMoreItems}
          text={loadMoreText}
        />
      </Loading>
    </div>
  );
}

export default LoadMoreButtonHelper;
