import React from 'react';

import { GADataType, IsDisabled } from '../../types';

import {
  YesNoAnyRadioButtonsValue,
  YesNoAnyRadioButtonsOnChange
} from './YesNoAnyRadioButtons.types';

import { RadioButtonInput } from '../RadioButtonInput';

import { words } from '../../locales/keys/words';

interface YesNoAnyRadioButtonsProps {
  disabled?: IsDisabled;
  name: string;
  onChange: YesNoAnyRadioButtonsOnChange;
  value: YesNoAnyRadioButtonsValue;
}

function YesNoAnyRadioButtons({
  dataGa,
  disabled,
  name,
  onChange,
  value
}: YesNoAnyRadioButtonsProps & GADataType) {
  return (
    <div className="text-sm">
      <div className="flex">
        <RadioButtonInput<YesNoAnyRadioButtonsValue>
          dataGa={`${dataGa}-yes-button`}
          name={name}
          value={value}
          option={YesNoAnyRadioButtonsValue.YES}
          i18nText={words.yes}
          disabled={disabled}
          onChange={onChange}
        />
        <RadioButtonInput<YesNoAnyRadioButtonsValue>
          dataGa={`${dataGa}-no-button`}
          name={name}
          value={value}
          option={YesNoAnyRadioButtonsValue.NO}
          i18nText={words.no}
          disabled={disabled}
          onChange={onChange}
        />
        <RadioButtonInput<YesNoAnyRadioButtonsValue>
          dataGa={`${dataGa}-any-button`}
          name={name}
          value={value}
          option={YesNoAnyRadioButtonsValue.ANY}
          i18nText={words.any}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default YesNoAnyRadioButtons;
